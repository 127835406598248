import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config, downloadConfig } from "../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";
const CsvOrders = (props) => {
  const [loader, setLoader] = useState("");
  const [csvpaydisabled, setCsvpaydisabled] = useState(true);
  const [csvPrice, setCsvPrice] = useState(0);
  const [validateLoader, setValidateLoader] = useState("");
  const [CSV, setCSV] = useState("");
  const [types, setTypes] = useState([]);
  const [activeUspsType, setActiveUspsType] = useState();
  const [uspsValidateDisabled, setUspsValidateDisabled] = useState(false);
  const [Weight, setWeight] = useState(0);
  const [csvOrders, setCsvOrders] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [isRequestSent, setIsRequestSent] = useState(false);

  useEffect(() => {
    readLabelTypes();
    readCsvOrders();
  }, []);

  const readLabelTypes = async () => {
    await axios
      .get(server + "/api/v1/labeltype/read", config)
      .then((res) => {
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validateCSV = async (e) => {
    e.preventDefault();

    setValidateLoader(<Loader />);

    const params = new FormData();
    params.append("csv", CSV);
    params.append("labeltype", activeUspsType);

    await axios
      .post(server + "/api/v1/order/validatecsv", params, config)
      .then((res) => {
        setValidateLoader("");
        setCsvPrice(res.data.totalPrice);
        setCsvpaydisabled(false);
        toast.success("CSV validated successfully");
      })
      .catch((err) => {
        setValidateLoader("");
        toast.error(err.response.data.message);
      });
  };

  const createOrderFromCSV = async (e) => {
    e.preventDefault();
    isRequestSent && toast.error("Request already sent");
    setLoader(<Loader />);
    setIsRequestSent(true);

    const params = new FormData();
    params.append("csv", CSV);
    params.append("type", activeUspsType);
    params.append("total_price", csvPrice);
    params.append("design", templateType);

    await axios
      .post(server + "/api/v1/order/createOrderFromCSV", params, config)
      .then((res) => {
        toast.success("Orders created successfully");
        setCsvpaydisabled(true);
        setCSV("");
        setWeight(0);
        setCsvPrice(0);
        setLoader("");
        readCsvOrders();
        setIsRequestSent(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoader("");
        setIsRequestSent(false);
      });
  };

  const readCsvOrders = async () => {
    await axios
      .get(server + "/api/v1/order/read-bulk-orders", config)
      .then((res) => {
        setCsvOrders(res.data.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadZip = async (id) => {
    await axios
      .get(`${server}/api/v1/order/bulk-download/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const downloadCSV = async (id) => {
    await axios
      .get(`${server}/api/v1/order/bulk-download-csv/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const downloadPdf = async (id) => {
    await axios
      .get(`${server}/api/v1/order/bulk-download-pdf/${id}`, downloadConfig)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className="nk-wrap">
      {/* main header @s */}
      <Header sidebarRef={props.sidebarRef} />
      {/* main header @e */}
      {/* content @s */}
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">Create CSV Order</h5>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <label className="form-label" htmlFor="default-01">
                              LabelType
                            </label>
                            <select
                              name="labeltype"
                              id=""
                              className="form-control"
                              onChange={(e) => {
                                setActiveUspsType(e.target.value);

                                if (e.target.value !== "") {
                                  setSelectedType(
                                    types.find((type) => {
                                      return type._id === e.target.value;
                                    }).uid
                                  );
                                } else {
                                  setSelectedType("");
                                }
                              }}
                            >
                              <option value="">Select LabelType</option>
                              {types.map((type, index) => (
                                <option key={index} value={type._id}>
                                  {type.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        {selectedType === "priority" && (
                          <div className="col-12">
                            <div className="form-group mb-3">
                              <label className="form-label">Template</label>
                              <select
                                onChange={(e) => {
                                  setTemplateType(e.target.value);
                                }}
                                required
                                name="template"
                                className="form-control"
                              >
                                <option value=""> Select type</option>
                                <option value="pitney">Pitney Bowes</option>
                                {/* <option value="indica">Indica</option> */}
                                {/* <option value="stamps">Stamps</option> */}
                                <option value="evs">EVS</option>
                              </select>
                            </div>
                          </div>
                        )}

                        <div className="col-lg-12">
                          <div className="form-group mb-3">
                            <label className="form-label" htmlFor="groupInput">
                              CSV
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="CSV"
                              name="csv"
                              required
                              onChange={(e) => {
                                setCSV(e.target.files[0]);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mt-5">
                        <div>
                          <button
                            type="submit"
                            className="btn btn-lg btn-primary"
                            disabled={csvpaydisabled}
                            onClick={createOrderFromCSV}
                          >
                            Pay ${csvPrice} {loader}
                          </button>
                        </div>
                        <div>
                          <button
                            className="btn btn-gray mr-2"
                            disabled={uspsValidateDisabled}
                            onClick={validateCSV}
                          >
                            Validate CSV {validateLoader}
                          </button>
                          <a
                            href="/assets/csv_sample.csv"
                            className="btn  btn-info text-white"
                            download
                          >
                            <em className="icon ni ni-download" />
                            Download Sample CSV
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="card">
                    <div className="card-header">
                      <h5 className="card-title">CSV Orders</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Order Date</th>
                              <th>Orders</th>
                              <th>LabelType</th>
                              <th>Status</th>
                              <th>Price</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {csvOrders.map((order, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {new Date(order.createdAt).toDateString()}
                                </td>
                                <td>{order.orders?.length}</td>
                                <td>{order.labelType?.name}</td>
                                <td>
                                  {order.status === "completed" ? (
                                    <span className="badge badge-success">
                                      Completed
                                    </span>
                                  ) : (
                                    <span className="badge badge-warning">
                                      Pending
                                    </span>
                                  )}
                                </td>
                                <td>${order.total}</td>
                                <td>
                                  {order.status === "completed" && (
                                    <>
                                      <button
                                        className="btn btn-sm btn-primary"
                                        onClick={() => {
                                          downloadZip(order._id);
                                        }}
                                      >
                                        <em className="icon ni ni-download"></em>
                                        Zip
                                      </button>
                                      <button
                                        className="btn btn-sm btn-primary ml-2"
                                        onClick={() => {
                                          downloadCSV(order._id);
                                        }}
                                      >
                                        {/* download icon */}
                                        <em className="icon ni ni-download"></em>
                                        CSV
                                      </button>
                                      <button
                                        className="btn btn-sm btn-primary ml-2"
                                        onClick={() => {
                                          downloadPdf(order._id);
                                        }}
                                      >
                                        {/* download icon */}
                                        <em className="icon ni ni-download"></em>
                                        PDF
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CsvOrders;
